

































import { Component, ModelSync, Prop, Vue } from 'vue-property-decorator'

@Component
export default class QuantityField extends Vue {
  @ModelSync('value', 'change', { type: Number, default: 0 })
  countValue!: number

  @Prop({ default: 1 }) readonly step!: number

  @Prop({
    type: Boolean,
    default: false
  })
  readonly hideAddBtn!:boolean

  add():void {
    this.countValue += this.step
  }

  subtract():void{
    this.countValue -= this.step
  }
}
